html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*, *::before, *::after {
  box-sizing: inherit;
}
body {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: Euclid Circular B, Roboto, Arial;
  font-weight: 400;
  line-height: 1.43;
  min-width: 375px;
}

.dOqzll {
  display: flex;
  min-height: 100vh;
  background-color: rgb(255, 255, 255);
}
.CmQLs {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  .CmQLs {
    margin-left: unset;
    margin-right: unset;
  }
}
@media (max-width: 768px) {
  .dKZkmN {
    bottom: 75px;
    left: 20px;
    right: unset;
  }
}
.dKZkmN {
  position: fixed;
  bottom: 20px;
  z-index: 1;
  right: 20px;
  padding: 10px;
}
.jnhSCp.opened {
  transform: scaleY(1);
}
.jnhSCp {
  position: absolute;
  z-index: 1000;
  top: 5.3vh;
  height: auto;
  transform: scaleY(0);
  transform-origin: left top 0;
  transition: 0.2s linear;
  background-color: rgb(255, 255, 255);
  cursor: default;
  box-shadow: rgba(1, 1, 1, 0.1) 0 0.1vh 0.2vh 0;
  width: calc(100% + 3px);
  left: -1px;
  border-radius: 0 0 24px 24px;
}
.iMIQuQ {
  position: relative;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  width: 100%;
  height: 5.5vh;
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: 24px;
  font-family: Euclid Circular B, Roboto, Arial;
  cursor: pointer;
  user-select: none;
  transition: 0.2s linear;
  background-color: rgb(255, 255, 255) !important;
}
.cjTFGN:not(.selected) {
  height: 5.5vh;
  border-top: 0.1vh solid rgb(248, 248, 248);
  cursor: pointer;
}
.cjTFGN {
  display: flex;
  -moz-box-flex: 1;
  flex-grow: 1;
  -moz-box-align: center;
  align-items: center;
  padding: 0 0.5vh;
  background-color: rgb(255, 255, 255) !important;
}
.hzYsmE {
  height: 5vh;
  border-radius: 0.4vh;
  padding: 0;
}
.styles_broker__3x_dv {
  line-height: 1.2;
  height: 100px;
}

.bjsjKz {
  color: rgb(0, 0, 0);
  font-family: Euclid Circular B, Roboto, Arial;
  font-size: 1.8vh;
  text-align: center;
}

@import "fonts.scss";

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Light'), url('/assets/Euclid Circular B Light.ttf') format('truetype');
    font-weight: 100;
    font-style: normal
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Light'), url('/assets/Euclid Circular B Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Medium.ttf'), url('/assets/Euclid Circular B Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Bold'), url('/assets/Euclid Circular B Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Light Italic'), url('/assets/Euclid Circular B Light Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Light Italic'), url('/assets/Euclid Circular B Light Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Medium Italic'), url('/assets/Euclid Circular B Medium Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Bold Italic'), url('/assets/Euclid Circular B Bold Italic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Bold Italic'), url('/assets/Euclid Circular B Bold Italic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: euclid circular b;
    src: local('Euclid Circular B Bold'), url('/assets/Euclid Circular B Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal
}
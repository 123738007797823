.w-100 {
    width: 100% !important;
}
.text-center {
    text-align: center;
}
.d-none {
    display: none !important;
}
.d-flex {
    display: flex !important;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-self: center !important;
}

.gap-1 {
    gap: 8px;
}
.gap-2 {
    gap: 16px;
}
.logo {
  width: 60px;
}
.title {
  font-size: 2em;
  a {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    color: #00b3fb;
  }
}
@media(max-width:1280px) {
  .title {
    font-size: 1.4em;
  }
}